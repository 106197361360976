
import styles from "@components/DocumentationPage.module.scss";
import Seo from "@components/Seo";
import { withWebLayout } from "@components/web/Decorators";

export default withWebLayout((props: {
    children: any
}) => {
    if(typeof window === "undefined")
        return <Seo noIndex />;

    return <div className={styles.root}>
        <Seo noIndex />
        <div>
            {props.children}
        </div>
    </div>
});