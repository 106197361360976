import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export default (props: { title?: string, description?: string, image?: string, article?: boolean, noIndex?: boolean }) => {
  const { href, pathname } = useLocation();
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    siteUrl
  } = site.siteMetadata

  const seo = {
    title: props.title || defaultTitle,
    description: props.description,
    url: href || `${siteUrl}${pathname}`,
  };

  let canonicalUrl = seo.url.toLowerCase();
  while (canonicalUrl.endsWith("/"))
    canonicalUrl = canonicalUrl.substring(0, canonicalUrl.length - 1);

  /*
    Are you looking for og:title and og:image tags, as well as other Facebook Open Graph tags?
    Since this code injects meta-tags dynamically after the content is loaded via JavaScript and Facebook doesn't support JavaScript,
    we have to server-side redirect Facebook Crawler requests to a site that server-side-renders the meta-tags directly to them.
    This is done from the Cloudflare worker (workers-site/index.js).
  */
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {typeof props.noIndex === "boolean" &&
        <meta name="robots" content={props.noIndex ? "noindex" : "all"} />
      }
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        siteUrl: url
      }
    }
  }
`