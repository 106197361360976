import { LinkBox } from "@components/web/LinkBox";
import styles from "./Card.module.scss";

export function ReshopperCard(props: {
    children: any,
    className?: string,
    style?: React.CSSProperties,
    onClick?: () => void,
    href?: string
}) {
    const className = styles.card + " " + props.className;

    return <LinkBox {...props} className={className}>
        {props.children}
    </LinkBox>
}

export function ReshopperCardContent(props: {
    children: any,
    className?: string,
    style?: React.CSSProperties
}) {
    return <div className={`${styles.cardContent} ${props.className}`} style={props.style}>
        {props.children}
    </div>
}