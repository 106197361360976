import React from "react";
import styles from "./LinkBox.module.scss";

export function LinkBox(props: 
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> |
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>)
{
    if("href" in props && !!props.href)
    {
        return <a {...props as any} className={props.className + " " + styles.link}>
            {props.children}
        </a>;
    }

    return <div {...props as any}>
        {props.children}
    </div>;
}